
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class PropertyPartHeader extends Vue {
    @Prop() icon!: string;

    @Prop({ default: "" }) title!: string;

    @Prop({ default: "" }) subTitle!: string;
}
